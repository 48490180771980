@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-size: 18px;
}

@layer base {
  .panel-gradient {
    background: linear-gradient(
      117.54deg,
      rgba(255, 255, 255, 0.55 /* , 0.43*/) -19.85%,
      rgba(255, 255, 255, 0.55 /* , 0.43*/) -2.73%,
      rgba(255, 255, 255, 0.5 /* , 0.43*/) 14.39%,
      rgba(255, 255, 255, 0.47 /* , 0.45*/) 31.52%,
      rgba(255, 255, 255, 0.45 /* , 0.47*/) 48.64%,
      rgba(255, 255, 255, 0.43 /* , 0.5*/) 65.76%,
      rgba(255, 255, 255, 0.43 /* , 0.55*/) 82.88%,
      rgba(255, 255, 255, 0.43 /* , 0.55*/) 100%
    );

    box-shadow: 0px -8px 23px rgba(69, 69, 69, 0.17),
      0px 1px 24px -1px rgba(255, 255, 255, 0.18);

    backdrop-filter: blur(20px);
  }

  .h0 {
    @apply text-3xl font-bold text-title-color;
  }

  h1,
  .h1 {
    @apply text-2xl font-bold text-title-color md:text-4xl;
  }

  h2,
  .h2 {
    @apply text-xl font-bold text-title-color md:text-3xl;
  }

  h3,
  .h3 {
    @apply text-lg font-bold text-title-color md:text-2xl;
  }

  h4,
  .h4 {
    @apply text-base font-bold text-title-color md:text-lg;
  }

  body,
  .p {
    @apply text-title-color;
  }
}
